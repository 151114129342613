<template>
    <div>
        <!-- {{ detail }} -->
        <div class="row m-0" style="padding-bottom: 1rem; border-bottom: 1px solid #ececec;">
            <div class="col-6">
                <!-- <div class="row m-0 align-items-center">
                    <div class="col-4 p-0">
                        <label class="m-0" for="facilityName">{{ $t("설비명") }}</label>
                    </div>
                    <div class="col-8 p-0">
                        <input :value="detail.facilityName" class="form-control" id="facilityName" type="text" disabled>
                    </div>
                </div> -->
                <label for="facilityName">{{ $t("설비명") }}</label>
                <input :value="detail.facilityName" class="form-control" id="facilityName" type="text" disabled>
            </div>
            <div class="col-6">
                <label for="ctrlMode">{{ $t("제어 모드") }}</label>
                <input :value="detail.ctrlMode" class="form-control" id="ctrlMode" type="text" disabled>
            </div>
            <div class="col-12 mt-2">
                <label for="ctrlOutPut">{{ $t("제어 명령") }}</label>
                <textarea :value="JSON.stringify(detail.ctrlOutPut)" class="desc-ctn form-control" id="ctrlOutPut" disabled></textarea>
            </div>
        </div>
        <div class="row m-0" style="padding: 1rem 0px 1rem 0px; border-bottom: 1px solid #ececec;">
            <div class="col-4">
                <label for="setDt">{{ $t("제어 시작 시간") }}</label>
                <input :value="detail.setDt" class="form-control" id="setDt" type="text" disabled>
            </div>
            <div class="col-4">
                <label for="updDt">{{ $t("제어 종료 시간") }}</label>
                <input :value="detail.updDt" class="form-control" id="updDt" type="text" disabled>
            </div>
            <div class="col-4">
                <label for="state">{{ $t("제어 결과") }}</label>
                <input :value="detail.state" class="form-control" id="state" type="text" disabled>
            </div>
            <div class="col-12 mt-2">
                <label for="resultMsg">{{ $t("제어 상세 내용") }}</label>
                <textarea :value="detail.resultMsg" class="desc-ctn form-control" id="ctrlOutPut" disabled></textarea>
            </div>
        </div>
        <div class="row m-0" style="padding-top: 1rem;">
            <div class="col-4">
                <label for="serverName">{{ $t("연동 장치") }}</label>
                <input :value="detail.serverName" class="form-control" id="serverName" type="text" disabled>
            </div>
            <div class="col-4">
                <label for="driverType">{{ $t("연동 프로토콜") }}</label>
                <input :value="detail.driverType" class="form-control" id="driverType" type="text" disabled>
            </div>
            <div class="col-4">
                <label for="channelName">{{ $t("연동 위치") }}</label>
                <input :value="`${detail.channelName} / ${detail.facilityName}`" 
                    class="form-control" id="channelName" type="text" disabled>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ["detail"],
    compoenents: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}

</script>

<style scoped>
.desc-ctn {
    height: 100px;
    resize: none;
    overflow-y: auto;
}
</style>