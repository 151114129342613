<template>
    <div>
        <div class="row m-0">
            <div class="col-12" style="margin-bottom: 1rem;">
                <div class="row m-0">
                    <div class="col-5 p-0">
                        <select v-model="equipType" class="form-control" id="equipType" disabled>
                            <option :value="null">{{ $t("설비 유형 선택") }}</option>
                            <option v-for="type in $store.state.equipTypeList" :key="type.equipType"
                                :value="type.equipType">
                                {{ $t(`${type.equipTypeName}`) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-5">
                        <select v-model="group" class="form-control" id="zoneIdx">
                            <option :value="null">{{ $t("그룹 선택") }}</option>
                            <option v-for="group in gruopList" :value="group.sysNodeIdx" :key="group.sysNodeIdx">
                                {{ $t(`${group.sysNodeName}`) }}
                            </option>
                        </select>
                    </div>
                    <div class="col-2 p-0">
                        <button type="button" class="btn btn-secondary" @click="getEquipList"
                            style="width: 100%; height: 100%;">
                            {{ $t("조회") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12" style="height: 300px;">
                <TableList ref="table-list" :title="''" :columns="columns" :rows="equipList" :fixedHeader="false"
                    :keyField="'equipIdx'" :transCodes="transCodes" :useFilter="false" :mode="'edit'" :isSort="false"
                    :useMaxHeight="true" />
            </div>
        </div>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["type"],
    components: {
        TableList
    },
    data() {
        return {
            transCodes: [
                {
                    field: 'equipType',
                    codes: this.$store.state.transEquipType,
                },
                {
                    field: 'zoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
            ],
            columns: [
                {
                    label: this.$t("설비명"),
                    field: "equipName",
                    type: "text",
                },
                {
                    label: this.$t("설비 유형"),
                    field: "equipType",
                    type: "text",
                },
                {
                    label: this.$t("구역"),
                    field: "zoneIdx",
                    type: "text",
                },
            ],
            equipType: null,
            group: null,
            gruopList: [],
            equipList: [],
        }
    },
    computed: {
        zoneInfo() {
            return this.$store.state.zoneInfo
        }
    },
    watch: {},
    async created() {
        this.equipType = this.type ? this.type : null;
        await this.getEquipGroupList();
    },
    mounted() { },
    methods: {
        async getEquipGroupList() {
            try {
                let result = await backEndApi.equipControl.getEquipGroupList("Group", this.equipType);
                if (result.status == 200) this.gruopList = result.data;
                else await this.alertWarning(`${result.data.message}`);
            } catch (e) {
                this.gruopList = [];
                console.error(e)
            }
        },
        async getEquipList() {
            try {
                let result = await backEndApi.sysNode.getIncludedEquipList(this.group, "Y");
                if (result.status == 200) this.equipList = result.data;
                else {
                    this.equipList = [];
                    let detail = "";
                    if (result.data.errcode) await this.alertWarning(result.data.message)
                    else {
                        result.data.detail.forEach(item => detail += item)
                        await this.alertWarning(result.data.message, "", detail)
                    }
                    return;
                }
            } catch (e) {
                this.equipList = [];
                console.error(e);
            }
        },
        equipCheckList() {
            return this.$refs['table-list'].getCheckedRows()
        }
    },
}

</script>
