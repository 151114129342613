<template>
    <div v-if="exceptDay" class="row m-0">
        <div class="col-6 mb-2">
            <label for="exceptName">{{ $t("예외일명") }}</label>
            <!-- <input v-model="exceptDay.exceptName" type="text" class="form-control" placeholder="예외일명"
                :disabled="!isEditMode"> -->
            <valid-input :vid="'예외일명'" :classList="'form-control'" :inputType="'text'"
                :inputValue.sync="exceptDay.exceptName" :placeholder="$t('예외일명')" :rules="rules.COMMON_NAME_RULE"
                :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE" :disabled="!isEditMode"></valid-input>
        </div>
        <div class="col-6">
            <label for="exceptDt">{{ $t("예외일자") }}</label>
            <!-- <input v-model="exceptDay.exceptDt" type="text" class="form-control"> -->
            <date-picker v-model="exceptDay.exceptDt" placeholder="예외일자" vertical
                :config="{ format: 'YYYY-MM-DD', locale: 'ko' }" :disabled="!isEditMode"></date-picker>
        </div>
    </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

export default {
    props: ["data", "mode"],
    components: {},
    data() {
        return {
            exceptDay: null,
            rules,
            validMessage,
        }
    },
    computed: {
        isEditMode() {
            return this.mode !== 'view';
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        newData() {
            return {
                schdCtrlIdx: this.data.schdCtrlIdx,
                exceptName: '',
                exceptDt: '',
                enabled: 'Y',
            }
        },
        async setData(data) {
            if (data) {
                this.exceptDay = data;
            } else {
                this.exceptDay = this.newData();
            }
        },
        getData() {
            return this.exceptDay;
        },
    }
}

</script>
