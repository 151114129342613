<template>
    <!-- 시스템에 등록된 공휴일 또는 기존 스케줄에 등록된 예외일 선택 모달 -->
    <div>
        <div class="row" style="border: 1px solid #ececec; border-radius: 5px; margin: 0px 0px 15px 0px;">
            <div class="col-12" style="padding: 1rem;">
                <label for="exceptType">
                    <span style="font-weight: bold; font-size: 14px;">{{ $t("예외일 선택") }}</span>
                </label>
                <select v-model="holiday" class="form-control" id="exampleFormControlSelect1"
                    @change="selectExceptType">
                    <option value="">선택</option>
                    <option value="systemHoliday">공휴일 선택</option>
                    <option v-for="(schd, i) in scheduleList" :key="i" :value="schd.schdCtrlName">
                        {{ schd.schdCtrlName }}
                    </option>
                </select>
            </div>
        </div>
        <div v-if="!isEmpty(exceptList)" class="row" style="border: 1px solid #ececec; border-radius: 5px;">
            <div class="col-12" style="padding: 1rem;">
                <label for="table-list">
                    <span style="font-weight: bold; font-size: 14px;">{{ $t("예외일 목록") }}</span>
                </label>
                <TableList ref="table-list" :title="''" :columns="columns" :rows="exceptList" :fixedHeader="false"
                    :useFilter="false" :mode="'view'" :useMaxHeight="true" />
            </div>
        </div>
        <div v-else class="row m-0 placeholder" style="border: 1px solid #ececec; border-radius: 5px; height: 140px;">
            <span class="notification-icon">
                <i class="fas fa-external-link-alt"></i>
            </span>
            <span class="notification-text">예외일 유형을 선택하세요.</span>
        </div>
    </div>
</template>

<script>
import backEndApi from '@src/api/backEndApi';
import TableList from "@views/component/v2.1/ListDetailView/TableList.vue";

export default {
    props: ["data"],
    components: {
        TableList,
    },
    data() {
        return {
            scheduleList: [],
            exceptList: [],
            holidayList: [],
            baseHoliData: {
                baseDate: String(new Date().getFullYear()),
            },
            holiday: "",
            columns: [
                {
                    label: this.$t("예외일자"),
                    field: "exceptDt",
                    type: "text",
                },
                {
                    label: this.$t("예외일명"),
                    field: "exceptName",
                    type: "text",
                },
            ],
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getSchdCtrlList();
    },
    mounted() { },
    methods: {
        async getSchdCtrlList() {
            try {
                let result = await backEndApi.equipControl.getSchdCtrlList();
                if (result.status == 200) this.scheduleList = result.data
            } catch (e) {
                console.error(e)
            }
        },
        async selectExceptType() {
            this.exceptList = [];
            if (this.holiday == 'systemHoliday') {
                await this.getHolidayList();
                this.holidayList.forEach(item => {
                    this.exceptList.push({
                        schdCtrlIdx: this.data.schdCtrlIdx,
                        exceptName: item.holiName,
                        exceptDt: item.baseDate,
                        enabled: "Y"
                    })
                })
            } else {
                let find = this.scheduleList.find(item => item.schdCtrlName == this.holiday)
                if (find) {
                    find.exceptConfig.forEach(item => {
                        this.exceptList.push({
                            schdCtrlIdx: this.data.schdCtrlIdx,
                            exceptName: item.exceptName,
                            exceptDt: item.exceptDt,
                            enabled: "Y"
                        })
                    })
                }
            }
        },
        async getHolidayList() {
            try {
                let result = await backEndApi.calendar.getHolidayList(this.baseHoliData);
                if (result.status == 200) {
                    this.holidayList = result.data;
                }
            } catch (e) {
                console.error(e);
            }
        },
        getData() {
            return this.exceptList;
        },
    },
}

</script>

<style scoped>
.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notification-icon {
    padding: 1rem;
    color: #444;
    font-size: 30px;
}

.notification-text {
    font-size: 12px;
    font-weight: bold;
}
</style>