<!-- 설비유형코드 상세 (EquipTypeDetail) -->
<template>
  <vvo v-slot="v">
    <div class="detail-container">
      <!-- 1.선택 없음 표시 영역 -->
      <div v-if="!detailInfo" class="detail-placeholder">
        <span class="detail-notification-icon">
          <i class="fas fa-external-link-alt"></i>
        </span>
        <span class="detail-notification-text">제어 스케줄을 선택하세요.</span>
      </div>

      <!-- 2.상세 정보 제목 영역 -->
      <div style="height: calc(100% - 70px)" v-if="detailInfo">
        <div class="detail-header">
          <div class="col detail-title">
            <span class="title">{{ viewTitle }}</span>
            <span class="badge title1"> {{ detailInfo.schdCtrlIdx }} </span>
            <span class="badge title2"> {{ detailInfo.schdCtrlName }} </span>
          </div>
          <div class="detail-control">
            <b-form-checkbox switch v-model="isEditMode" size="lg" />
          </div>
        </div>

        <div v-if="detailInfo" class="detail-content">
          <BaseInfoPanel
            :detailInfo="detailInfo"
            :mode="mode"
            :equipTypeList="equipTypeList"
            @equipTypeChanged="monitorEquipType"
          />

          <!-- 탭 패널 영역 -->
          <div class="tab-panel-ctn" style="height: calc(100% - 238px)">
            <b-card no-body>
              <b-tabs card>
                <div v-for="type in equipTypeList" :key="type.equipType">
                  <b-tab v-if="type.checked" :title="`${type.equipTypeName}`">
                    <EquipTypePanel
                      :ref="`EquipTypePanel-${type.equipType}`"
                      :detailInfo="detailInfo"
                      :mode="mode"
                      :type="type.equipType"
                      :typeName="type.equipTypeName"
                    />
                  </b-tab>
                </div>
                <b-tab title="스케줄 설정">
                  <ScheduleSettingPanel
                    :detailInfo="detailInfo"
                    :mode="mode"
                    @editMode="setModeEdit"
                    @newMode="setModeNew"
                  />
                </b-tab>
                <b-tab title="예외일 설정">
                  <ExceptionDayPanel :detailInfo="detailInfo" :mode="mode" />
                </b-tab>
                <b-tab v-if="!isNewMode" title="제어 실행 이력">
                  <ControlHistoryPanel :detailInfo="detailInfo" :mode="mode" />
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>

      <!-- 3.하부 버튼 영역 -->
      <div class="detail-bottom" v-if="detailInfo">
        <div class="detail-bottom-info">
          <span>{{
            `관리자: ${detailInfo.regWorker ? detailInfo.regWorker : "-"}`
          }}</span>
          <span>{{ `생성일자: ${detailInfo.regDate ?? "-"}` }}</span>
          <span>{{ `수정일자: ${detailInfo.modDate ?? "-"}` }}</span>
        </div>

        <div class="detail-bottom-buttons mt-2">
          <!-- @click="handleSave(detailInfo)" -->
          <button
            type="button"
            class="btn btn-primary detail-btn"
            v-if="isEditMode"
            style="flex: 1"
            @click="onValidate(v, saveData)"
          >
            {{ $t("저장") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary detail-btn"
            v-if="detailInfo"
            style="flex: 1"
            @click="handleClose($event)"
          >
            {{ $t("닫기") }}
          </button>
        </div>
      </div>
    </div>
  </vvo>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import moment from "moment";
import BaseInfoPanel from "./panels/BaseInfoPanel.vue";
import EquipTypePanel from "./panels/EquipTypePanel.vue";
import ScheduleSettingPanel from "./panels/ScheduleSettingPanel.vue";
import ExceptionDayPanel from "./panels/ExceptionDayPanel.vue";
import ControlHistoryPanel from "./panels/ControlHistoryPanel.vue";

export default {
  components: {
    BaseInfoPanel,
    EquipTypePanel,
    ScheduleSettingPanel,
    ExceptionDayPanel,
    ControlHistoryPanel,
  },
  props: ["title", "schdCtrlIdx"],
  async created() {},
  async mounted() {},
  data() {
    return {
      detailInfo: null,
      mode: "view", // 편집모드 : edit/view
      equipTypeList: [],
      equipTypeTabList: [],
    };
  },
  computed: {
    isEditMode: {
      get() {
        return this.mode === "edit" || this.mode === "new";
      },
      set(value) {
        this.mode = value ? "edit" : "view";
      },
    },
    isNewMode() {
      return this.mode === "new";
    },
    isViewMode() {
      return this.mode === "view";
    },
    viewTitle() {
      return this.title;
    },
  },
  watch: {
    schdCtrlIdx() {
      if (this.isEmpty(this.schdCtrlIdx)) {
        this.clearData();
      } else {
        this.loadData();
      }
    },
  },
  methods: {
    async monitorEquipType({ equipType, isChecked, equipTypeName }) {
      console.log(equipType);
      if (isChecked) {
        console.log("isChecked true");
        this.detailInfo.targets.push({
          equipType: equipType,
        });
        await this.alertNoti(
          `${equipTypeName} 유형을 사용합니다. 설비 그룹 또는 개별 설비를 설정해주세요.`
        );
      } else {
        await this.$refs[`EquipTypePanel-${equipType}`][0].clearData();
        const targets = this.detailInfo.targets.filter(
          (target) => target.equipType !== equipType
        );
        const equips = this.detailInfo.equips.filter(
          (equip) => equip.equipType !== equipType
        );
        const groups = this.detailInfo.groups.filter(
          (group) => group.equipType !== equipType
        );
        this.detailInfo.targets = targets;
        this.detailInfo.equips = equips;
        this.detailInfo.groups = groups;
        console.log("isChecked false");
        console.log(this.detailInfo.targets);
        console.log(this.detailInfo.equips);
        console.log(this.detailInfo.groups);

        await this.alertNoti(`${equipTypeName} 유형을 미사용 합니다.`);
      }
    },
    async notifyCancelEdit() {
      if (this.isEditMode) {
        const result = await this.alertConfirmWarning(
          "편집중인 내용을 무시하고 종료할까요?"
        );
        if (result.value) return true;
        else return false;
      }

      return true;
    },

    async newData() {
      this.mode = "new";

      this.detailInfo = {
        schdCtrlName: null,
        startDate: null,
        endDate: null,
        zoneIdx: null,
        // lastEventRetry: "",
        description: null,
        enabled: "Y",
        targets: [],
        groups: [],
        equips: [],
        repeatConfig: {},
        exceptConfig: [],
        events: [],
      };

      await this.getEquipTypeList();

      return this.detailInfo;
    },

    async loadData() {
      if (this.schdCtrlIdx) {
        try {
          let result = await backEndApi.equipControl.getEquipCtrlSchDetail(
            this.schdCtrlIdx
          );

          if (result.status == 200) {
            result.data.regDate = result.data.regDate
              ? moment(result.data.regDate).format("YYYY-MM-DD HH:mm:ss")
              : null;
            result.data.modDate = result.data.modDate
              ? moment(result.data.modDate).format("YYYY-MM-DD HH:mm:ss")
              : null;
            result.data.startDate = result.data.startDate
              ? moment(result.data.startDate).format("YYYY-MM-DD")
              : null;
            result.data.endDate = result.data.endDate
              ? moment(result.data.endDate).format("YYYY-MM-DD")
              : null;
            result.data.events = result.data.events.map((item) => {
              let event = { ...item };
              event.regDate = moment(event.regDate).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return event;
            });
            this.detailInfo = { ...result.data };
            this.mode = "view";
            await this.getEquipTypeList();
          } else await this.alertWarning(`${result.data.message}`);
        } catch (e) {
          this.alertNoti("데이터를 조회할 수 없습니다.");
          console.error(e);
        }
      } else {
        this.newData();
      }
    },

    clearData() {
      this.detailInfo = null;
      this.mode = "view";

      this.$forceUpdate();
    },

    async saveData() {
      // if (this.isEmpty(data.schdCtrlName)) {
      //     this.alertWarning("스케줄명은 필수 입력사항입니다.");
      //     return;
      // }

      this.detailInfo.targets.forEach((item) => {
        let equips = this.$refs[`EquipTypePanel-${item.equipType}`][0].equips;
        let groups = this.$refs[`EquipTypePanel-${item.equipType}`][0].groups;

        if (!this.isEmpty(equips)) {
          equips.forEach((equip) => {
            let find = this.detailInfo.equips.find(
              (detail) => detail.equipIdx == equip.equipIdx
            );
            if (this.isEmpty(find)) this.detailInfo.equips.push(equip);
          });
        }

        if (!this.isEmpty(groups)) {
          groups.forEach((group) => {
            let find = this.detailInfo.groups.find(
              (detail) => detail.sysNodeName == group.sysNodeName
            );
            if (this.isEmpty(find)) this.detailInfo.groups.push(group);
          });
        }
      });

      Object.keys(this.detailInfo.repeatConfig).forEach((key) => {
        if (!this.detailInfo.repeatConfig[key])
          delete this.detailInfo.repeatConfig[key];
        else this.detailInfo.repeatConfig[key] = "Y";
      });

      let result;

      try {
        result = await backEndApi.equipControl.saveEquipCtrlSch(
          this.detailInfo
        );
        if (result.status == 200) {
          await this.alertNoti("데이터를 저장하였습니다..");
          this.$emit("data-updated");
          this.mode = "view";
        } else await this.alertWarning(`${result.data.message}`);
      } catch (e) {
        this.alertNoti("데이터를 저장할 수 없습니다.");
        console.error(e);
      }
    },

    getData() {
      return this.detailInfo;
    },

    async handleSave(info) {
      await this.saveData(info);
    },

    async handleClose(event) {
      const result = await this.notifyCancelEdit();
      if (!result) {
        event && event.preventDefault();
        return false;
      }
      this.clearData();
      this.$emit("closed");

      return true;
    },

    async getEquipTypeList() {
      this.equipTypeList = [];
      const list = this.$store.state.equipTypeList.filter(
        (item) => !this.isEmpty(item.ctrlModeCnt)
      );
      list.forEach((item) => {
        if (!this.isEmpty(this.detailInfo.targets)) {
          let type = {
            equipType: item.equipType,
            equipTypeName: item.equipTypeName,
          };
          let find = this.detailInfo.targets.find(
            (target) => target.equipType == type.equipType
          );
          if (find) type.checked = true;
          else type.checked = false;
          this.equipTypeList.push(type);
        } else {
          let type = {
            equipType: item.equipType,
            equipTypeName: item.equipTypeName,
            checked: false,
          };
          this.equipTypeList.push(type);
        }
      });
    },
    setModeEdit() {
      this.mode = "edit";
    },
    setModeNew() {
      this.mode = "new";
    },
  },
};
</script>

<style scoped>
.detail-container .card .tabs .tab-content .card-body {
  padding-top: 0px;
}

.detail-notification-icon {
  padding: 1rem;
  color: #444;
  font-size: 48px;
}

.detail-notification-text {
  font-size: 15px;
  font-weight: bold;
}

.detail-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 1rem 1rem 1rem 1rem;

  display: flex;
  flex-direction: column;

  border-radius: 10px;
  font-size: 12px;
}

.detail-placeholder {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-header {
  margin-bottom: 0px !important;
  padding: 8px 16px 8px 16px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px 6px 0px 0px;
  border: solid #ececec 1px;
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
  font-weight: bold;
}

.detail-header .detail-title {
  padding: 0 !important;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-content {
  height: Calc(100% - 50px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;

  background-color: white;
  border: solid #ececec 1px;
  border-radius: 0px 0px 6px 6px;
}

.detail-control {
  margin-bottom: 8px !important;
  display: flex;
  justify-content: flex-end;
}

.detail-bottom {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40 px;
}

.detail-bottom-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.detail-btn {
  /* height: 50px; */
  flex: 1;
  margin: 0px 4px 0px 4px;
}

.detail-bottom-info {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 8px 4px 8px;
}

.title {
  /* font-size: 14px; */
  font-weight: 600;
  margin-right: 20px;
}

.badge {
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
  display: inline-block;
  min-width: 20px;
  padding: 0px 10px 0px 10px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  /* background-color: #777; */
  line-height: 22px;
  border-radius: 8px;
}

.badge.title1 {
  background-color: #ff6d00;
  color: #fff;
}

.badge.title2 {
  background-color: #546e7a;
  color: #fff;
}

.badge.others {
  background-color: #cfd8dc;
  color: #000;
}

.tab-panel-ctn {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  overflow: hidden;
}
</style>